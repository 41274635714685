import { StyledContainer } from "../../../../styles/global";
import {
  StyledDropdownLinkWrapper,
  StyledLayout,
  StyledLogo,
  StyledMenu,
  StyledMenuLink,
  StyledMobileHeader,
  StyledMobileLayout,
  StyledMobileMenu,
  StyledPageHeader,
  StyledSubMenuText,
} from "./style";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { generateMenu, MenuList } from "../../../../constants/menu";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Drawer, Dropdown, Space, theme } from "antd";
import VuiButton from "../../../../components/button";
import MenuIcon from "../../../../assets/icons/menu";
import CloseIcon from "../../../../assets/icons/close";
import { ItemType } from "antd/es/menu/hooks/useItems";
import clsx from "clsx";

const { useToken } = theme;

const PageHeader = () => {
  const { t } = useTranslation();
  const { token } = useToken();
  const menus = generateMenu(t);
  const location = useLocation();

  const loadMenus = useMemo(() => {
    return menus.map((menu) => {
      if (menu.children && menu.children.length > 0) {
        return (
          <Dropdown
            key={menu.key}
            dropdownRender={() => (
              <StyledDropdownLinkWrapper
                backgroundColor={token.colorBgElevated}
                borderRadius={token.borderRadiusLG}
                boxShadow={token.boxShadowSecondary}
              >
                <Space direction="vertical" size={14}>
                  {menu.children &&
                    menu.children.map((item) => (
                      <StyledMenuLink
                        status={
                          location.pathname === item.link
                            ? "active"
                            : "not-active"
                        }
                        isDropdownLink
                        key={item.key}
                        to={item.link}
                      >
                        {item.name}
                      </StyledMenuLink>
                    ))}
                </Space>
              </StyledDropdownLinkWrapper>
            )}
          >
            <StyledSubMenuText>{menu.name}</StyledSubMenuText>
          </Dropdown>
        );
      }

      return (
        <StyledMenuLink
          status={location.pathname === menu.link ? "active" : "not-active"}
          key={menu.key}
          to={menu.link}
        >
          {menu.type === "button" ? (
            <VuiButton>{menu.name}</VuiButton>
          ) : (
            menu.name
          )}
        </StyledMenuLink>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menus]);

  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const navigate = useNavigate();

  const onClickMobileMenu = useCallback(
    (url: string) => {
      navigate(url);
      setIsOpenMenu(false);
    },
    [navigate]
  );

  const mappingMenuItems = useCallback((menus: MenuList[]): ItemType[] => {
    return menus.map((menu) => ({
      key: menu.key,
      label: menu.name,
      className: "active",
      onClick: () => (menu.children ? {} : onClickMobileMenu(menu.link)),
      ...(menu.children ? { children: mappingMenuItems(menu.children) } : {}),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 10) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("load", handleScroll);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.addEventListener("load", handleScroll);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <StyledPageHeader
      className={clsx({
        scrolled: isScrolled,
      })}
    >
      <StyledContainer>
        <StyledLayout className="header-layout">
          <Link to="/">
            <StyledLogo>
              <img src="/logo.svg" alt="logo" />
            </StyledLogo>
          </Link>

          <StyledMenu>{loadMenus}</StyledMenu>
        </StyledLayout>

        <StyledMobileLayout>
          <StyledMobileHeader>
            <StyledLogo>
              <img src="/logo.svg" alt="logo" />
            </StyledLogo>

            {isOpenMenu ? (
              <CloseIcon onClick={() => setIsOpenMenu(!isOpenMenu)} />
            ) : (
              <MenuIcon onClick={() => setIsOpenMenu(!isOpenMenu)} />
            )}
          </StyledMobileHeader>

          <Drawer
            placement="left"
            closable={false}
            onClose={() => setIsOpenMenu(!isOpenMenu)}
            open={isOpenMenu}
            rootStyle={{
              top: 80,
            }}
            maskStyle={{
              backgroundColor: "white",
            }}
            contentWrapperStyle={{
              boxShadow: "none",
            }}
            drawerStyle={{
              padding: 0,
            }}
            bodyStyle={{
              padding: 0,
            }}
            width="100%"
          >
            <StyledContainer>
              <StyledMobileMenu mode="inline" items={mappingMenuItems(menus)} />
            </StyledContainer>
          </Drawer>
        </StyledMobileLayout>
      </StyledContainer>
    </StyledPageHeader>
  );
};

export default PageHeader;
