import React from "react";

const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.7433 0.489467C16.5452 0.494145 16.357 0.576998 16.2198 0.719936L9.00012 7.93966L1.78039 0.719936C1.71048 0.647943 1.62684 0.59071 1.53441 0.551625C1.44198 0.512541 1.34265 0.492401 1.2423 0.492397C1.09304 0.492433 0.947187 0.537001 0.823398 0.620397C0.699609 0.703794 0.603515 0.822226 0.547413 0.960542C0.49131 1.09886 0.47775 1.25077 0.508468 1.39683C0.539185 1.5429 0.612782 1.67648 0.719843 1.78048L7.93957 9.00021L0.719843 16.2199C0.647865 16.289 0.590399 16.3718 0.550812 16.4634C0.511224 16.555 0.490311 16.6536 0.489297 16.7534C0.488282 16.8531 0.507187 16.9521 0.544904 17.0445C0.58262 17.1369 0.638391 17.2208 0.70895 17.2914C0.779508 17.3619 0.863436 17.4177 0.955818 17.4554C1.0482 17.4931 1.14718 17.512 1.24696 17.511C1.34674 17.51 1.44531 17.4891 1.53691 17.4495C1.6285 17.4099 1.71128 17.3525 1.78039 17.2805L9.00012 10.0608L16.2198 17.2805C16.289 17.3525 16.3717 17.4099 16.4633 17.4495C16.5549 17.4891 16.6535 17.51 16.7533 17.511C16.8531 17.512 16.952 17.4931 17.0444 17.4554C17.1368 17.4177 17.2207 17.3619 17.2913 17.2914C17.3618 17.2208 17.4176 17.1369 17.4553 17.0445C17.493 16.9521 17.5119 16.8531 17.5109 16.7534C17.5099 16.6536 17.489 16.555 17.4494 16.4634C17.4098 16.3718 17.3524 16.289 17.2804 16.2199L10.0607 9.00021L17.2804 1.78048C17.3895 1.6758 17.4643 1.54061 17.4952 1.39263C17.5261 1.24465 17.5116 1.09079 17.4535 0.951215C17.3955 0.811641 17.2966 0.69287 17.1698 0.610472C17.0431 0.528075 16.8944 0.485897 16.7433 0.489467Z"
      fill="black"
    />
  </svg>
);

export default CloseIcon;
