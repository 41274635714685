import {
  StyledContainer,
  StyledShowDesktop,
  StyledShowMobile,
} from "../../../../styles/global";
import { StyledLogo } from "../header/style";
import { Col, Form, Input, Row, Space, Spin } from "antd";
import {
  StyledCopyright,
  StyledFooter,
  StyledInfoWrapper,
  StyledLeftFooter,
  StyledSubscribeText,
  StyledTitle,
} from "./style";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import MailIcon from "../../../../assets/icons/mail";
import PhoneIcon from "../../../../assets/icons/phone";
import TelephoneIcon from "../../../../assets/icons/telephone";
import {
  handleBackendError,
  openNotification,
  translation,
} from "../../../../utils/helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import SubscribeRepository from "../../../../repositories/subscribe-repository";
import { AxiosError } from "axios";
import YoutubeIcon from "../../../../assets/icons/youtube";
import FacebookIcon from "../../../../assets/icons/facebook";
import InstagramIcon from "../../../../assets/icons/instagram";
import { Setting } from "../../../../models/setting";
import LinkedinIcon from "../../../../assets/icons/linkedin";

export type SubscribePayload = {
  email: string;
};

export type PageFooterProps = {
  settingData?: Setting;
};

const PageFooter = ({ settingData }: PageFooterProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [form] = Form.useForm<SubscribePayload>();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const onFinish = useCallback(async (data: SubscribePayload) => {
    setSubmitLoading(true);

    await SubscribeRepository.create(data)
      .then(() => {
        openNotification(
          "success",
          translation(t, "notification.success.subscribe")
        );
        form.resetFields();
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, translation(t, "notification.error.default"));
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  }, []);

  const renderSocialMedia = useMemo(() => {
    return (
      <Space
        size={36}
        align="center"
        style={{ marginTop: 24, marginBottom: 0 }}
      >
        <Link to={settingData?.facebook_link || "/"}>
          <FacebookIcon />
        </Link>
        <Link to={settingData?.instagram_link || "/"}>
          <InstagramIcon />
        </Link>
        <Link to={settingData?.linkedin_link || "/"}>
          <LinkedinIcon />
        </Link>
        <Link to={settingData?.youtube_link || "/"}>
          <YoutubeIcon />
        </Link>
      </Space>
    );
  }, [settingData]);

  useEffect(() => {
    form.resetFields();
  }, [pathname]);

  return (
    <StyledFooter>
      <StyledContainer>
        <Link to="/">
          <StyledLogo>
            <img src="/logo.svg" alt="logo" />
          </StyledLogo>
        </Link>

        <Row gutter={[32, 32]} style={{ marginTop: 24 }}>
          <Col md={11} xs={24}>
            <StyledLeftFooter>
              
              <StyledShowDesktop>
                <div style={{ marginBottom: 32 }}>{settingData?.description}</div>
              </StyledShowDesktop>

              <StyledShowMobile>
                {settingData?.description}
              </StyledShowMobile>

              <StyledShowDesktop>
                <StyledTitle>{t("common.text.getLatestNews")}</StyledTitle>

                <Form form={form} layout="vertical" onFinish={onFinish}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: translation(t, "validation.required", {
                          item: translation(t, "common.text.email"),
                        }),
                      },
                      {
                        type: "email",
                        message: translation(t, "validation.invalid", {
                          item: translation(t, "common.text.email"),
                        }),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={translation(t, "common.text.writeYourEmail")}
                      suffix={
                        submitLoading ? (
                          <Spin spinning={submitLoading} />
                        ) : (
                          <StyledSubscribeText onClick={() => form.submit()}>
                            {translation(t, "common.text.subscribe")}
                          </StyledSubscribeText>
                        )
                      }
                    />
                  </Form.Item>
                </Form>

                {renderSocialMedia}

                <StyledCopyright>
                  Copyright © 2023 Nutrilab Pratama
                </StyledCopyright>
              </StyledShowDesktop>
            </StyledLeftFooter>
          </Col>

          <Col md={6} xs={16}>
            <StyledTitle>{t("common.text.menu")}</StyledTitle>

            <Space direction="vertical" size={14}>
              <Link to="/">{t("common.text.home")}</Link>
              <Link to="/about">{t("common.text.about")}</Link>
              <Link to="/product">{t("common.text.product")}</Link>
              <Link to="/promotion">{t("common.text.promotion")}</Link>
              <Link to="/news">{t("common.text.news")}</Link>
              <Link to="/product-article">
                {t("common.text.productArticle")}
              </Link>
              <Link to="/contact">{t("common.text.contact")}</Link>
            </Space>
          </Col>

          <Col md={7} xs={24}>
            <StyledInfoWrapper>
              <div>
                <StyledTitle>{t("common.text.address")}</StyledTitle>

                <div>{settingData?.address}</div>
              </div>

              <div>
                <StyledTitle>{t("common.text.contact")}</StyledTitle>

                <Space direction="vertical" size={14}>
                  <Space align="center" size={12}>
                    <MailIcon />
                    <div style={{ fontSize: 14 }}>{settingData?.email}</div>
                  </Space>

                  <Space align="center" size={12}>
                    <PhoneIcon />
                    <div style={{ fontSize: 14 }}>
                      {settingData?.phone_number}
                    </div>
                  </Space>

                  <Space align="center" size={12}>
                    <TelephoneIcon />
                    <div style={{ fontSize: 14 }}>
                      {settingData?.office_phone_number}
                    </div>
                  </Space>
                </Space>
              </div>
            </StyledInfoWrapper>

            <StyledShowMobile style={{ marginTop: 36 }}>
              <StyledTitle>{t("common.text.getLatestNews")}</StyledTitle>

              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: translation(t, "validation.required", {
                        item: translation(t, "common.text.email"),
                      }),
                    },
                    {
                      type: "email",
                      message: translation(t, "validation.invalid", {
                        item: translation(t, "common.text.email"),
                      }),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={translation(t, "common.text.writeYourEmail")}
                    suffix={
                      submitLoading ? (
                        <Spin spinning={submitLoading} />
                      ) : (
                        <StyledSubscribeText onClick={() => form.submit()}>
                          {translation(t, "common.text.subscribe")}
                        </StyledSubscribeText>
                      )
                    }
                  />
                </Form.Item>
              </Form>

              {renderSocialMedia}

              <StyledCopyright>
                Copyright © 2023 Nutrilab Pratama
              </StyledCopyright>
            </StyledShowMobile>
          </Col>
        </Row>
      </StyledContainer>
    </StyledFooter>
  );
};

export default PageFooter;
